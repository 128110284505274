import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Lazy, Navigation} from "swiper";

export default function AppSwiper() {
    return (
        <>
            <Swiper
                navigation={true}
                loop={true}
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                    clickable: true,
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 5,
                        spaceBetween: 50,
                    },
                }}
                modules={[Lazy, Navigation]}
            >
                {/*<SwiperSlide><img src="images/front/app_swiper/login_screen.jpg" alt=""/></SwiperSlide>*/}
                <SwiperSlide><img src="images/front/app_swiper/home_screen.jpg" alt=""/></SwiperSlide>
                <SwiperSlide><img src="images/front/app_swiper/messages_screen.jpg" alt=""/></SwiperSlide>
                <SwiperSlide><img src="images/front/app_swiper/vote_screen.jpg" alt=""/></SwiperSlide>
                <SwiperSlide><img src="images/front/app_swiper/tickets_screen.jpg" alt=""/></SwiperSlide>
                <SwiperSlide><img src="images/front/app_swiper/documents_screen.jpg" alt=""/></SwiperSlide>
                <SwiperSlide><img src="images/front/app_swiper/news_screen.jpg" alt=""/></SwiperSlide>
                <SwiperSlide><img src="images/front/app_swiper/show_news_screen.jpg" alt=""/></SwiperSlide>
                <SwiperSlide><img src="images/front/app_swiper/contacts_screen.jpg" alt=""/></SwiperSlide>
            </Swiper>
        </>
    );
}
