import React from 'react';

const LightBox = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        if (window.matchMedia("(min-width: 769px)").matches) {
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <img className={'preview'} src={props.url_min} onClick={handleClickOpen}/>
            <div onClick={handleClose} className={open ? 'modal-open' : 'modal-hide'}>
                <div className={'modal-image-wrapper'}>
                    <img src={props.url_max} className={'modal-img'}/>
                </div>
            </div>
        </div>
    )
}
export default LightBox