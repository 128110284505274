import ReactOnRails from 'react-on-rails';

import LightBox from '../bundles/front/LightBox';
import AppSwiper from '../bundles/front/AppSwiper';
import Price from '../bundles/front/Price';


ReactOnRails.register({
    LightBox, AppSwiper, Price
});
