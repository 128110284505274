import React from 'react';
import TextField from '@mui/material/TextField';
import styled from '@emotion/styled'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 6px;
`
const Title = styled.div`
  text-align: center;
  background: #E91E63;
  color: #fff;
  font-weight: bold;
  border-radius: 6px 6px 0 0;
  padding: 10px;
  font-size: 20px;
`
const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const InputBlock = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
  margin: 10px 0;
`
const Question = styled.div`
  margin: 0 10px;
  font-weight: bold;
`
const TdName = styled.td`
  padding: 10px;
  font-weight: bold;
`
const GreenCost = styled.span`
  color: #43A047;
  font-weight: bold;
`

const Td = styled.td`
  padding: 10px;
  text-align: right;
`


const Price = () => {
    const [plots_count, setPlotsCount] = React.useState(100);
    const [show_cost, setShowCost] = React.useState(false);

    const platformPerMonth = () => {
        if (plots_count <= 100){
            console.log(1)
            return 1900
        } else if (101 <= plots_count && plots_count <= 150){
            console.log(2)
            return 2500
        } else if (150 < plots_count && plots_count<= 200){
            console.log(3)
            return 3500
        } else if (200 < plots_count && plots_count<= 300){
            console.log(4)
            return 5000
        } else if (300 < plots_count){
            console.log(5)
            return (6000 + (plots_count - 300) * 10)
        }
    }
    const appPerMonth = () => {
        if (plots_count <= 100){
            return 2100
        } else if (101 <= plots_count && plots_count <= 150){
            return 2700
        } else if (150 < plots_count && plots_count <= 200){
            return 3800
        } else if (200 < plots_count && plots_count <= 300){
            return 5300
        } else if (300 < plots_count){
            return (6300 + (plots_count - 300) * 10)
        }
    }

    const platformPerYear = () => {
        return platformPerMonth() * 12
    }
    const appPerYear = () => {
        return appPerMonth() * 12
    }
    const totalCostPerMonth = () => {
        return platformPerMonth() + appPerMonth()
    }
    const totalCostPerYear = () => {
        return platformPerYear() + appPerYear()
    }
    const formatCost = (cost) => {
        return new Intl.NumberFormat().format(cost)
    }


    const changePlots = (event) => {
        setPlotsCount(event.target.value)
        setShowCost(true)
    }

    return (
        <Wrapper>
            <Title>Калькулятор Вашего тарифа</Title>
            <DataWrapper>
                <InputBlock>
                    <Question>
                        Сколько участков в Вашем поселке?
                    </Question>
                    <TextField type="number" id="standard-basic" variant="standard" value={plots_count}
                               onChange={changePlots}
                               style={{width: 60}}
                               inputProps={{ min: "0", max: "99999", step: "1" }}/>
                </InputBlock>
                {show_cost &&
                    <table>
                        <thead>
                        <tr>
                            <Td/>
                            <Td>Месяц</Td>
                            <Td>Год</Td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <TdName>Сайт и платформа</TdName>
                            <Td><GreenCost>{formatCost(platformPerMonth())}</GreenCost> <i className="far fa-ruble-sign"
                                                                                          aria-hidden="true"></i></Td>
                            <Td><GreenCost>{formatCost(platformPerYear())}</GreenCost> <i className="far fa-ruble-sign"
                                                                                         aria-hidden="true"></i></Td>
                        </tr>
                        <tr>
                            <TdName>Мобильное приложение</TdName>
                            <Td><GreenCost>{formatCost(appPerMonth())}</GreenCost> <i className="far fa-ruble-sign"
                                                                                     aria-hidden="true"></i></Td>
                            <Td><GreenCost>{formatCost(appPerYear())}</GreenCost> <i className="far fa-ruble-sign"
                                                                                    aria-hidden="true"></i></Td>
                        </tr>
                        <tr>
                            <TdName>Общая стоимость</TdName>
                            <Td><GreenCost>{formatCost(totalCostPerMonth())}</GreenCost> <i className="far fa-ruble-sign"
                                                                                           aria-hidden="true"></i></Td>
                            <Td><GreenCost>{formatCost(totalCostPerYear())}</GreenCost> <i className="far fa-ruble-sign"
                                                                                          aria-hidden="true"></i></Td>
                        </tr>
                        </tbody>
                    </table>

                }
            </DataWrapper>


        </Wrapper>
    )
}

export default Price
